<template>
  <div class="tile">
    <h1>
      <img src="@/assets/img/logo.gif" alt="Ayayahuasca!" />
    </h1>
    <img
      src="@/assets/img/tourist-welcome.png"
      alt="A tourisst looking for some trips"
      class="portrait"
    />
    <h2>
      Not your turn to play, but be ready to provide your best drugs soon!
    </h2>
  </div>
</template>
