<template>
  <div class="tile">
    <h1>
      <img src="@/assets/img/logo.gif" alt="Ayayahuasca!" />
    </h1>
    <img
      src="@/assets/img/tourist-welcome.png"
      alt="A tourisst looking for some trips"
      class="portrait"
    />
    <h2>Start the game on the main screen once everyone has joined.</h2>
  </div>
</template>
